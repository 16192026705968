
import { Component } from '@/lib/decorator';

import TitleReturn from '../../components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import Loading from '@/components/Loading.vue';
import FormBlock from './profile/_form.vue';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';

import PartnersModule from '@/store/partners';
import PartnerEntityModule from '@/store/partners/entity';
import Form from '@/layouts/Form';
import { TabsNavInterface } from '@/interfaces/tabs.interface';

@Component({
  components: {
    AppFieldset,
    AppRow,
    AppCol,
    FormBlock,
    Loading,
    TitleReturn,
    TabsNav,
  },
  async beforeMount() {
    this.partnerId = this.$route.params.partnerId as string;
    await PartnerEntityModule.initEdit(this.partnerId);
  },
})
export default class PartnersEntityEdit extends Form {
  partnerId = '';

  get isTest(): boolean {
    return PartnerEntityModule.model.isTest;
  }

  get titlePage(): string {
    return PartnersModule.pageSettings.titleEdit;
  }

  get tabsNav(): TabsNavInterface {
    return PartnerEntityModule.tabsNav;
  }

  get currentTab(): string {
    return PartnerEntityModule.tabsNav.profile?.id;
  }

  returnBefore(): void {
    this.$router.push({ name: 'partners' });
  }
}
